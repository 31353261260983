import React, { useState } from 'react';
import './video.css';
// import ReactPlayer from 'react-player';
import video from '../../image/video.mp4';
import volume from '../../image/volume.png';
import volumeD from '../../image/volumeD.png';
import IconNextVideo from '../../image/iconNextVideo.png';
const Video = () => {
  // const video_id = process.env.REACT_APP_YOUTUBE_VIDEO_ID;
  // const video_url = 'https://www.youtube.com/watch?v=' + video_id;
  const [volumeType, setVolumeType] = useState('0');
  const [muted, setMuted] = useState(true);
  console.log(process.env);
  return (
    <div class="videos">
      <div class="video-wrap">
        <div class="play-btn">
          {volumeType === '0' ? (
            <img
              className="volumeImg"
              width="70px"
              height="70px"
              src={volumeD}
              onClick={() => {
                setVolumeType('1');
                setMuted(false);
              }}
            />
          ) : (
            <img
              className="volumeImg"
              width="70px"
              height="70px"
              src={volume}
              onClick={() => {
                setVolumeType('0');
                setMuted(true);
              }}
            />
          )}
        </div>
        <video
          class="video-player"
          src={video}
          type="video/mp4"
          autoPlay={true}
          muted={muted}
          controls={false}
          playsInline
          onEnded={() => {
            window.location.replace('#/home');
          }}
        ></video>
        <div class="next-btn-container">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
            onClick={() => window.location.replace('#/home')}
          >
            <div
              style={{
                width: '75%',
                marginTop: '3px',
              }}
            >
              Passer cette vidéo
            </div>
            <div
              style={{
                width: '20%',
                paddingRight: '5%',
              }}
            >
              <img src={IconNextVideo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
