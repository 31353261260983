import React from 'react';
import Menu from './Menu/Menu';
import './HomePage.css';
// import background2 from '../../image/background2.png';
// import logo2 from '../../image/logo2.png';
const HomePage = () => {
  return (
    <>
      <div className="zoom">
        <div className="backgroundImg">
          <Menu />
        </div>
        {/* <div className="logo2">
          <img
            onClick={() => window.open('http://sidiharazem.ma/', '_blank')}
            src={logo2}
          />
        </div>
        <div className="backgroundFotter">
          <img src={background2} />
        </div> */}
      </div>
    </>
  );
};

export default HomePage;
