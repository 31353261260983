import React from 'react';
import {
  // EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
  // EmailIcon,
} from 'react-share';
import { Col, Modal, Row } from 'react-bootstrap';
const ModalShare = props => {
  const { show, handleClose } = props;
  return (
    <div>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>partager</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col>
                {' '}
                <FacebookShareButton url="http://sidiharazem.ma/accueil">
                  <FacebookIcon
                    logoFillColor="white"
                    round={true}
                  ></FacebookIcon>
                </FacebookShareButton>
              </Col>
              {/* <Col>
                {" "}
                <EmailShareButton
                  url="https://sidiharazem.ma/accueil"
                  // quote={"hello"}
                >
                  <EmailIcon logoFillColor="white" round={true}></EmailIcon>
                </EmailShareButton>
              </Col> */}
              <Col>
                <LinkedinShareButton url="http://sidiharazem.ma/accueil">
                  <LinkedinIcon
                    logoFillColor="white"
                    round={true}
                  ></LinkedinIcon>
                </LinkedinShareButton>
              </Col>
              <Col>
                <TwitterShareButton url="http://sidiharazem.ma/accueil">
                  <TwitterIcon logoFillColor="white" round={true}></TwitterIcon>
                </TwitterShareButton>
              </Col>
              <Col>
                <WhatsappShareButton url="http://sidiharazem.ma/accueil">
                  <WhatsappIcon
                    logoFillColor="white"
                    round={true}
                  ></WhatsappIcon>
                </WhatsappShareButton>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ModalShare;
