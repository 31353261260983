import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import logo from '../../../image/logo.png';
import '../Menu/Menu.css';
const ModalReclamation = props => {
  const { show, handleClose } = props;
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Modal.Title>
              <img className="logo" src={logo} />
            </Modal.Title>
            <Form.Control
              className="inputR"
              type="email"
              placeholder="Champ 1"
            />
            <Form.Control
              className="inputR"
              type="email"
              placeholder="Champ 2"
            />
            <Form.Control
              className="inputR"
              type="email"
              placeholder="Champ 3"
            />
            <Form.Control
              className="inputR"
              type="email"
              placeholder="Champ 4"
            />
            <Button className="btnR" variant="primary" onClick={handleClose}>
              Envoyer
            </Button>
          </Form.Group>
        </Modal.Header>
        {/* <Modal.Body>
          
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default ModalReclamation;
