import React, { useState, useRef } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import './Menu.css';
import ModalReclamation from '../ModalReclamation/ModalReclamation';
import ModalShare from '../ModaleShare/ModalShare';
import img1 from '../../../image/img1.png';
import img2 from '../../../image/img2.png';
import img3 from '../../../image/img3.png';
import img4 from '../../../image/img4.png';
import img5 from '../../../image/img5.png';
import logo from '../../../image/logo.png';
import logo2 from '../../../image/logo2.png';
import background2 from '../../../image/backgroundFooter.png';
const Menu = () => {
  //modal Reclamation
  const [showRec, setShowRec] = useState(false);
  const handleCloseRec = () => setShowRec(false);
  // const handleShowRec = () => setShowRec(true);
  // modal Share
  const [showShare, setShowShare] = useState(false);
  const handleCloseShare = () => setShowShare(false);
  const handleShowShare = () => setShowShare(true);
  const EndRef = useRef(null);
  // const scrollToBottom = () => {
  //   EndRef.current.scrollIntoView({ behavior: 'smooth' });
  // };
  // const disableScrolling = () => {
  //   document.body.style.overflow = 'hidden';
  // };
  // useEffect(
  //   () => disableScrolling(),
  //   []
  // );
  return (
    <>
      <ModalReclamation show={showRec} handleClose={handleCloseRec} />
      <ModalShare show={showShare} handleClose={handleCloseShare} />
      <div scroll="no" className="menu">
        <Row className="justify-content-md-center">
          <Col lg={4} sm={8}>
            <img className="logo" src={logo} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={() => window.open('https://sidiharazem.ma/', '_blank')}
              style={{ float: 'right' }}
              size="lg"
              variant="light "
            >
              <div className="mb-2">
                <img width="47px" src={img1} />
              </div>
              <div style={{ width: '130px', textAlign: 'center' }}>
                Notre <br /> marque
              </div>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() =>
                window.open('https://sidiharazem.ma/boutique/#/', '_blank')
              }
              style={{ float: 'left' }}
              size="lg"
              variant="light "
            >
              <div className="mb-2">
                <img width="65px" src={img2} />
              </div>
              <div style={{ width: '130px', textAlign: 'center' }}>
                Acheter <br /> en ligne
              </div>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              style={{ float: 'right' }}
              onClick={() =>
                window.open(
                  'https://sidiharazem.ma/formulairecontact',
                  '_blank'
                )
              }
              size="lg"
              variant="light "
              // onClick={() => {
              //   handleShowRec();
              // }}
            >
              <div className="mb-2">
                <img width="55px" src={img3} />
              </div>
              <div style={{ width: '130px', textAlign: 'center' }}>
                Ecrivez- <br />
                nous
              </div>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() =>
                window.open('https://sidiharazem.ma/formulairerh', '_blank')
              }
              style={{ float: 'left' }}
              size="lg"
              variant="light "
            >
              <div className="mb-2">
                <img width="47px" src={img4} />
              </div>
              <div style={{ width: '130px', textAlign: 'center' }}>
                Rejoignez- <br />
                nous
              </div>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className="btnShare"
              size="lg"
              onClick={() => {
                handleShowShare();
              }}
            >
              <img width="47px" src={img5} />
              Partager cette expérience
            </Button>
          </Col>
        </Row>
        <div className="backgroundFotter">
          <img src={background2} />
        </div>
        <div className="logo2">
          <img
            onClick={() => window.open('http://sidiharazem.ma/', '_blank')}
            src={logo2}
          />
        </div>
        <div ref={EndRef} />
      </div>
    </>
  );
};

export default Menu;
