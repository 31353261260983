import React, { useEffect, useState } from 'react';
import logo from '../../image/logo.png';
import './Loading.css';

const Loading = () => {
  const [style, setStyle] = useState({
    opacity: 0.7,
    background: '#FFF',
    width: `150px`,
    zIndex: 9999,
    position: 'absolute',
    height: '114px',
    marginTop: '-30px',
    border: 'none',
  });

  useEffect(() => {
    Loadion();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.location.replace('#/video');
    }, 2500);
  }, [style]);

  useEffect(() => {
    setTimeout(() => {
      setStyle({
        opacity: '1',
        zIndex: 9999,
        background: '#FFFFF',
        width: `50%`,
      });
    }, 2000);
  }, [style]);
  const Loadion = () => {
    setTimeout(() => {
      setStyle({
        opacity: 0.7,
        background: '#FFF',
        width: '75px',
        marginLeft: ' 56px',
        height: '114px',
        marginTop: '-30px',
        position: 'absolute',
      });
    }, 1000);
  };
  return (
    <>
      <div className="containerLoading">
        <div className="zoom">
          <div className="progressContainer">
            <div className="progressLogo">
              <div style={style}></div>
              <img className="progressImg" src={logo} />
              <br />
              <div className="progressText">Chargement ...</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
