import React from 'react';
import HomePage from './compenent/homePage/HomePage';
import Loading from './compenent/loading/Loading';
import { HashRouter, Route, Switch } from 'react-router-dom';
// const createBrowserHistory = require('history').createBrowserHistory;

import Video from './compenent/Video/Video';
function App() {
  return (
    <>
      {/* <HomePage/> */}
      {/* <Loading/> */}
      <HashRouter>
        <Switch>
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/" component={Loading} />
          <Route exact path="/video" component={Video} />
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
